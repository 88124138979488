import React, { useState } from "react";

const defaultValue = {
  brandMainColor: "rgba(175, 22, 133)",
  textMainColor: "white",
};

export const ThemeContext = React.createContext();

export const ThemeProvider = (props) => {
  const [theme, setTheme] = useState(defaultValue);
  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
};

export function withThemeContext(Component) {
  return function WithThemeContextComponent(props) {
    return (
      <ThemeContext.Consumer>
        {({ theme }) => <Component {...props} theme={theme} />}
      </ThemeContext.Consumer>
    );
  };
}
