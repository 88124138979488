import "core-js/features/array/find";
import "core-js/features/array/includes";
import "core-js/features/array/some";
import "core-js/features/object/values";
import React from "react";
import ReactDOM from "react-dom";
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "semantic-ui/dist/semantic.min.css";
import "./app.css";

import * as Sentry from "@sentry/browser";

import {
  UserProvider,
  ApiProvider,
  MessagesProvider,
  ThemeProvider,
} from "./components/Context";
import Root from "./screens/Root";

// Renders the app with contexts providers.
const renderApp = () => {
  Sentry.init({
    dsn: "https://f9d021a42727493c83111af4f2192f94@bugs.iteisa.com/4",
    environment:
      process.env.REACT_APP_ENVIRONMENT === "production"
        ? "production"
        : "development",
  });

  ReactDOM.render(
    <UserProvider>
      <ApiProvider>
        <MessagesProvider>
          <ThemeProvider>
            <Root />
          </ThemeProvider>
        </MessagesProvider>
      </ApiProvider>
    </UserProvider>,
    document.getElementById("root")
  );
};

renderApp();
