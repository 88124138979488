import React, { PureComponent, createContext } from "react";
import axios from "axios";
import * as Sentry from '@sentry/browser'

import { withUserContext } from "./User";

import { APIBASEURL } from "../../globals";

const ApiContext = createContext();

class ApiProviderWithoutUserContext extends PureComponent {
  state = {};

  apiRequest = (type, endpoint, data = {}, logoutWhenError = true) => {
    return new Promise((resolve, reject) => {
      axios({
        method: type,
        url: `${APIBASEURL}${endpoint}`,
        responseType: "json",
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          Sentry.captureException(error);
          if (logoutWhenError) this.props.user.logoutUser();
          console.error(error);
          reject(error);
        });
    });
  };

  getRequest = (endpoint) => this.apiRequest("get", endpoint);

  postRequest = (endpoint, data) => this.apiRequest("post", endpoint, data);

  putRequest = (endpoint, data) => this.apiRequest("put", endpoint, data);

  deleteRequest = (endpoint, logoutWhenError = true) =>
    this.apiRequest("delete", endpoint, {}, logoutWhenError);

  render() {
    const api = {
      ...this.state,
      apiRequest: this.apiRequest,
      getRequest: this.getRequest,
      postRequest: this.postRequest,
      putRequest: this.putRequest,
      deleteRequest: this.deleteRequest,
    };
    return (
      <ApiContext.Provider value={api}>
        {this.props.children}
      </ApiContext.Provider>
    );
  }
}

export const ApiProvider = withUserContext(ApiProviderWithoutUserContext);

export function withApiContext(Component) {
  return function WithApiContextComponent(props) {
    return (
      <ApiContext.Consumer>
        {(api) => <Component {...props} api={api} />}
      </ApiContext.Consumer>
    );
  };
}
