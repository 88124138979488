import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "@reach/router";
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth } from "date-fns";
import { Container, Tab, Label, Button } from "semantic-ui-react";

import { withUserContext } from "../Context";
import { TableBackEnd } from "../UI";

import { formatDatetime } from "../../utils";
import { APIBASEURL, APIREQUESTOPTIONS } from "../../globals";

class NextEvents extends PureComponent {
  static propTypes = {
    mode: PropTypes.string.isRequired,
  };

  makeURlFromMode = (mode) => {
    const weekStart = startOfWeek(new Date(), { weekStartsOn: 1 })
      .toISOString()
      .split("T")[0];
    const weekEnd = endOfWeek(new Date(), { weekStartsOn: 1 })
      .toISOString()
      .split("T")[0];
    const monthStart = startOfMonth(new Date()).toISOString().split("T")[0];
    const monthEnd = endOfMonth(new Date()).toISOString().split("T")[0];

    const urlSuffix =
      mode === "today"
        ? `?date=${new Date().toISOString().split("T")[0]}&`
        : mode === "oldies"
        ? "/oldies"
        : mode === "week"
        ? `?date_gte=${weekStart}&date_lte=${weekEnd}&`
        : mode === "month"
        ? `?date_gte=${monthStart}&date_lte=${monthEnd}&`
        : "";
    return `${APIBASEURL}/events${urlSuffix}`;
  };

  header = {
    id: { text: "Id", collapsing: true },
    ...(this.props.user.userIsDeity()
      ? { owner: { text: "Propietario" } }
      : {}),
    date: { text: "Fecha" },
    itemAndCompany: { text: "Equipo/Empresa" },
    description: { text: "Descripción" },
    actionType: { text: "Tipo de acción" },
    actionPriority: { text: "Prioridad" },
    actions: { text: "Acciones", collapsing: true },
  };

  dataExtraction = (data) => {
    return Object.values(data.data).map((v, i) => ({
      id: v.id,
      ...(this.props.user.userIsDeity() ? { owner: v.owner.name } : {}),
      date: formatDatetime(v.date, false),
      itemAndCompany: (
        <div>
          <div>
            <Link to={`/items/${v.series.item.id}`}>
              <strong>{v.series.item ? v.series.item.name : null}</strong>
            </Link>
          </div>
          <div>
            <Link to={`/companies/${v.series.company.id}`}>
              {v.series.company ? v.series.company.name : null}
            </Link>
          </div>
        </div>
      ),
      description: v.series.description,
      actionType: v.series.action_type ? v.series.action_type.name : null,
      actionPriority: v.series.action_priority ? (
        <Label
          basic
          color={v.series.action_priority.color}
          content={v.series.action_priority.name}
        />
      ) : null,
      actions: (
        <div style={{ display: "flex", flexFlow: "row nowrap" }}>
          {v.action_id === null && (
            <Link to={`/actions/form`} state={{ event: v }}>
              <Button basic compact icon="add" />
            </Link>
          )}
          {v.action_id !== null && (
            <Link to={`/actions/form/${v.action_id}`}>
              <Button
                compact
                content={<div style={{ whiteSpace: "nowrap" }}>Ver acción</div>}
              />
            </Link>
          )}
        </div>
      ),
    }));
  };

  totalPagesExtraction = (data) => data.pagination.page_count;

  componentDidMount() {
    //document.getElementsByName('search')[0].focus()
  }

  handleApiRequestError = (error) => {
    console.error(error);
    this.props.user.logoutUser();
    //if (error.response.data.code === 401) this.props.user.logoutUser()
  };

  render() {
    const { mode } = this.props;

    return (
      <TableBackEnd
        compact
        url={this.makeURlFromMode(mode)}
        apiRequestOptions={APIREQUESTOPTIONS}
        header={this.header}
        dataExtraction={this.dataExtraction}
        totalPagesExtraction={this.totalPagesExtraction}
        hasSearch={false}
        hasSort={false}
        hasPagination={true}
        defaultPageSize={10}
        handleApiRequestError={this.handleApiRequestError}
      />
    );
  }
}

class NextEventsWidget extends PureComponent {
  panes = [
    {
      menuItem: "Hoy",
      pane: (
        <Tab.Pane key="today" padded>
          <NextEvents user={this.props.user} mode="today" />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Esta semana",
      pane: (
        <Tab.Pane key="week" padded>
          <NextEvents user={this.props.user} mode="week" />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Este mes",
      pane: (
        <Tab.Pane key="month" padded>
          <NextEvents user={this.props.user} mode="month" />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Expirados",
      pane: (
        <Tab.Pane key="oldies" padded>
          <NextEvents user={this.props.user} mode="oldies" />
        </Tab.Pane>
      ),
    },
  ];

  render() {
    return (
      <Container fluid style={{ padding: "2rem" }}>
        <h3>Eventos</h3>
        <Tab panes={this.panes} renderActiveOnly={false} />
      </Container>
    );
  }
}

export default withUserContext(NextEventsWidget);
