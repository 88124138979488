import React, { useState } from "react";
import { Link } from "@reach/router";
import { Segment, Button } from "semantic-ui-react";
import styled from "styled-components";

import UserData from "./UserData";
import MainMenu from "./MainMenu";
import Logo from "./Logo";
import { SpaceBetween } from "./UI";

import { SITE } from "../globals";
import { useScreenSize } from "./../hooks";

const StyledButton = styled(Button)`
  > i {
    color: ${SITE.color1};
  }
`;

const Header = () => {
  const [screenWidth] = useScreenSize();
  const [showVerticalMenu, setShowVerticalMenu] = useState(false);

  const displayFullContent = ["xl", "2xl"].includes(screenWidth);
  const isSmallDevice = ["sm", "md", "lg"].includes(screenWidth);

  return (
    <header>
      <SpaceBetween
        wrapper={Segment}
        style={{
          alignItems: "stretch",
          display: "flex",
          flexWrap: isSmallDevice ? "wrap" : "no-wrap",
        }}
      >
        <div
          style={{
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "center",
          }}
        >
          <Link to="/">
            <Logo
              style={{
                height: SITE.logo.headerHeight,
              }}
            />
          </Link>
        </div>
        {displayFullContent && (
          <React.Fragment>
            <MainMenu />
            <UserData />
          </React.Fragment>
        )}
        {!displayFullContent && (
          <div
            style={{
              display: "flex",
              flexWrap: "nowrap",
            }}
          >
            <UserData />
            <StyledButton
              onClick={() => setShowVerticalMenu(!showVerticalMenu)}
              basic
              icon={showVerticalMenu ? "close" : "bars"}
            />
          </div>
        )}
        {isSmallDevice && showVerticalMenu && (
          <React.Fragment>
            <SpaceBetween style={{ width: "100%" }}>
              <MainMenu
                vertical={true}
                afterMenuItemSelection={() => setShowVerticalMenu(false)}
              />
            </SpaceBetween>
          </React.Fragment>
        )}
      </SpaceBetween>
    </header>
  );
};

export default Header;
