import { useState, useEffect, useCallback } from "react";

const screenSizes = {
  "2xl": 1536,
  xl: 1280,
  lg: 1024,
  md: 768,
  sm: 0,
};

export const useScreenSize = (sizeCorrespondance = screenSizes) => {
  const [screenSize, setscreenSize] = useState(["2xl", 0]);

  const currenWidth = screenSize[0];
  const updateScreenSize = useCallback(() => {
    const nextSize = Object.entries(sizeCorrespondance).find(
      ([, limit]) => window.innerWidth > limit
    );

    if (!nextSize) return;

    const [nextWidth] = nextSize;
    //if is equal then prevent set state
    if (currenWidth !== nextWidth) {
      setscreenSize([nextWidth, window.innerHeight]);
    }
  }, [currenWidth, sizeCorrespondance]);

  useEffect(() => {
    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener("resize", updateScreenSize);
  }, [updateScreenSize]);

  return screenSize;
};
