import React from "react";
import { Segment, Container, Label } from "semantic-ui-react";

import { withUserContext } from "../Context";
import { TableBackEnd } from "../UI";
import CrudRowButtons from "../CrudRowButtons";

import { formatDatetime } from "../../utils";
import { APIBASEURL, APIREQUESTOPTIONS } from "../../globals";

const OpenIncidences = (props) => {
  const url = `${APIBASEURL}/public/incidences`;

  const header = {
    id: { text: "Id", collapsing: true },
    code: { text: "Código" },
    date: { text: "Fecha/Hora" },
    placeAndDescription: { text: "Lugar/Descripción" },
    area: { text: "Área" },
    status: { text: "Estado" },
    ...(props.user.userPrivileges["actions"].update
      ? { actions: { text: "Acciones", collapsing: true } }
      : {}),
  };

  const dataExtraction = (data) => {
    return Object.values(data.data).map((v, i) => ({
      id: v.id,
      code: <div style={{ whiteSpace: "nowrap" }}>{v.code}</div>,
      date: (
        <div style={{ whiteSpace: "nowrap" }}>
          {formatDatetime(v.created_at)}
        </div>
      ),
      placeAndDescription: (
        <div>
          <div>
            <strong>{v.place}</strong>
          </div>
          <div>{v.description}</div>
        </div>
      ),
      area: v.area ? (
        <div style={{ whiteSpace: "nowrap" }}>{v.area.name}</div>
      ) : (
        "-"
      ),
      status:
        v.actions.length === 0 ? (
          <Label basic content="Pendiente" />
        ) : (
          <Label
            basic
            color="orange"
            content={<div style={{ whiteSpace: "nowrap" }}>En proceso</div>}
          />
        ),
      actions: (
        <div style={{ display: "flex", flexFlow: "row nowrap" }}>
          <CrudRowButtons
            section="incidences"
            itemId={v.id}
            skippedActions={["delete"]}
          />
        </div>
      ),
    }));
  };

  const totalPagesExtraction = (data) => data.pagination.page_count;

  const handleApiRequestError = (error) => {
    console.error(error);
  };

  return (
    <Container fluid style={{ padding: "2rem" }}>
      <Segment padded vertical>
        <h3>Incidencias abiertas</h3>
        <TableBackEnd
          url={url}
          apiRequestOptions={APIREQUESTOPTIONS}
          header={header}
          dataExtraction={dataExtraction}
          totalPagesExtraction={totalPagesExtraction}
          hasSearch={false}
          hasSort={false}
          hasPagination={true}
          handleApiRequestError={handleApiRequestError}
          striped
        />
      </Segment>
    </Container>
  );
};

export default withUserContext(OpenIncidences);
