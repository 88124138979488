import React from "react";
import PropTypes from "prop-types";
import { Input } from "semantic-ui-react";

export const ListSearchFilter = ({ onChange, search }) => {
  return (
    <Input
      width={8}
      fluid
      placeholder="Buscar..."
      name="search"
      value={search}
      onChange={({ target }) => onChange(target.value)}
    />
  );
};

ListSearchFilter.propTypes = {
  onChange: PropTypes.func,
};

ListSearchFilter.defaultProps = {
  onChange: () => null,
};
