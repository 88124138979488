import React from "react";
import { Label, Icon } from "semantic-ui-react";

export const Active = ({ active, ...rest }) => (
  <Label
    basic
    color={active ? "green" : "red"}
    content={active ? "SÍ" : "NO"}
    {...rest}
  />
);

export const BooleanIndicator = ({ isActive, ...rest }) => (
  <Icon
    color={isActive ? "green" : "red"}
    name={isActive ? "check" : "close"}
    {...rest}
  />
);
