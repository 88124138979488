import { useReducer } from "react";

const formReducer = (state, newState) => {
  return { ...state, ...newState };
};

export const useFormReducer = (initialFormState) => {
  const [state, dispatch] = useReducer(formReducer, initialFormState);
  const handleValueChange = (_, { name, type, value, checked }) =>
    dispatch({ [name]: type === "checkbox" ? checked : value });

  return [state, handleValueChange];
};
