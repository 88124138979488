import {
  REQUEST_STARTED,
  REQUEST_SUCCEEDED,
  REQUEST_FAILED,
  SET_SEARCH,
  PAGINATION_CHANGE,
  SORT_CHANGE,
  DATE_BOUNDS_CHANGE,
} from "./actions";

const isGraterOrEqualsDate = (startDateString, endDateString) => {
  if (!startDateString || !endDateString) return true;

  const start = new Date(startDateString);
  const end = new Date(endDateString);

  if (end.getFullYear() < start.getFullYear()) return false;
  if (end.getFullYear() > start.getFullYear()) return true;
  //If this goes around here, that means year is the same;
  if (end.getMonth() < start.getMonth()) return false;
  if (end.getMonth() > start.getMonth()) return true;
  //If this goes around here, that means month is the same;
  return end.getDate() > start.getDate();
};

const reducer = (state, { type: actionType, payload: actionPayload }) => {
  switch (actionType) {
    case REQUEST_STARTED:
      return { ...state, status: "loading" };
    case REQUEST_SUCCEEDED:
      return {
        ...state,
        dataRaw: actionPayload.data,
        totalPages: actionPayload.totalPages,
        status: "ok",
      };
    case REQUEST_FAILED:
      return {
        ...state,
        dataRaw: null,
        status: "error",
        activePage: 1,
        totalPages: 1,
      };
    case SET_SEARCH:
      return {
        ...state,
        search: actionPayload.searchTerm,
        activePage: 1,
        selectedItemsList: {},
        allItemsPageSelected: {},
      };
    case SORT_CHANGE:
      return {
        ...state,
        sortColumn: actionPayload.sortColumn,
        sortDirection:
          state.sortColumn === actionPayload.sortColumn
            ? state.sortDirection === "desc"
              ? "asc"
              : "desc"
            : "asc",
        selectedItemsList: {},
        allItemsPageSelected: {},
      };
    case PAGINATION_CHANGE:
      return { ...state, activePage: actionPayload.activePage };
    case DATE_BOUNDS_CHANGE:
      const [dateLowerLimit, dateUpperLimit] =
        actionPayload.inputName === "detectionDateStarts"
          ? [actionPayload.value, state.detectionDateEnds]
          : [state.detectionDateStarts, actionPayload.value];

      const [dateValue, dateBoundsError] = isGraterOrEqualsDate(
        dateLowerLimit,
        dateUpperLimit
      )
        ? [actionPayload.value, false]
        : ["", true];

      return {
        ...state,
        [actionPayload.inputName]: dateValue,
        dateBoundsError,
      };
    default:
      return state;
  }
};

export default reducer;
