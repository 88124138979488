import { useEffect, useState, useCallback } from "react";
import axios from "axios";

import { APIREQUESTOPTIONS, requestStates } from "../globals";

export const useFetch = (url, forceFetch) => {
  const [requestData, setRequestData] = useState({
    status: requestStates.SENT,
    data: [],
  });

  const getRequest = useCallback(
    (cancelTokenSource) => {
      setRequestData((prevState) => ({
        ...prevState,
        status: requestStates.SENT,
      }));
      return axios({
        method: "get",
        url,
        cancelToken: cancelTokenSource.token,
        ...APIREQUESTOPTIONS,
      });
    },
    [url]
  );

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    getRequest(cancelTokenSource)
      .then(({ data }) => {
        setRequestData({
          status: requestStates.SUCCESS,
          data,
        });
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.log("error here");
          setRequestData({
            status: requestStates.FAILED,
            data: err,
          });
        }
      });
    return () =>
      cancelTokenSource.cancel("Petición cancelada from table backend");
  }, [getRequest, forceFetch]);

  return requestData;
};
