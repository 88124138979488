import React from 'react'
import { Transition, Message } from 'semantic-ui-react'

import { withMessagesContext } from './Context'

const Messages = ({
  globalMessages: { error, warning, success, info, resetMessages },
}) => {
  return (
    <Transition
      visible={
        error.length > 0 ||
        warning.length > 0 ||
        success.length > 0 ||
        info.length > 0
      }
      animation="fly up"
      duration={600}
    >
      <div
        style={{
          position: 'fixed',
          zIndex: '2000',
          bottom: '0',
          left: '0',
          right: '0',
          margin: '2rem',
        }}
      >
        {error.length > 0 && (
          <Message
            floating
            error
            header="Upss!!, algo va mal..."
            list={error}
            onDismiss={() => resetMessages('error')}
          />
        )}
        {warning.length > 0 && (
          <Message
            floating
            warning
            header="Advertencia"
            list={warning}
            onDismiss={() => resetMessages('warning')}
          />
        )}
        {success.length > 0 && (
          <Message
            floating
            success
            header="Completado"
            list={success}
            onDismiss={() => resetMessages('success')}
          />
        )}
        {info.length > 0 && (
          <Message
            floating
            info
            header="Información"
            list={info}
            onDismiss={() => resetMessages('info')}
          />
        )}
      </div>
    </Transition>
  )
}

export default withMessagesContext(Messages)
