import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

export const formatDatetime = (dtSource, hasTime = true, hasDate = true) => {
  if (!dtSource) return null

  return format(
    typeof dtSource === 'string' ? parseISO(dtSource + 'Z') : dtSource + 'Z',
    `${hasDate ? 'dd/MM/yyyy' : ''}${hasDate && hasTime ? ' ' : ''}${hasTime ? 'HH:mm' : ''}`
  )
}

export const formatNumber = (number = 0) => {
  return new Intl.NumberFormat('es-ES').format(number)
}

export const formatCurrency = (amount = 0) => {
  return new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
  }).format(amount)
}

export const formatPhone = (phone, separator = ' ') => {
  return phone.replace(/\B(?=(\d{3})+(?!\d))/g, separator)
}

export const flattenObject = (obj, prefix = '') =>
  Object.keys(obj).reduce((t, k) => {
    const item = obj[k]
    const element =
      item && typeof item === 'object'
        ? flattenObject(item, `${prefix}${k}/`)
        : [`${prefix}${k}: ${item}`]
    return [...t, ...element]
  }, [])

export const toLocaleISOString = dt => {
  const dateFields = dt
    .toLocaleDateString('es-ES', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .split('/')
  const time = dt.toLocaleTimeString('en-US', { hour12: false })
  return `${dateFields[2]}-${dateFields[1]}-${dateFields[0]}T${time}`
}
