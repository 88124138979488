import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Input, Card, Pagination, Container } from "semantic-ui-react";

import { useScreenSize } from "../../hooks";

const defaultCardsBySize = {
  "2xl": 7,
  xl: 5,
  lg: 5,
  md: 3,
  sm: 1,
};

export const CardResultsListFrontend = ({
  data,
  hasSearch,
  hasPagination,
  pageSize,
  defaultPage,
  hasSort,
  searchPredicate,
  ItemComponent,
  cardsBySize = defaultCardsBySize,
}) => {
  //HOOKS
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(defaultPage);
  const [cardsQuantity, setCardsQuantity] = useState(cardsBySize["2xl"]);
  const [screenWidth] = useScreenSize();

  useEffect(() => {
    setCardsQuantity(cardsBySize[screenWidth]);
  }, [screenWidth, cardsBySize]);

  //CHANGE HANDLER
  const handlePageChange = (_, { activePage }) => setCurrentPage(activePage);

  //Render calculations
  const filteredItems = data.filter((item) => searchPredicate(item, search));

  const totalPages = Math.ceil(filteredItems.length / pageSize) || 1;

  const paginationStartsAt = (currentPage - 1) * pageSize;
  const paginationEndsAt = paginationStartsAt + pageSize;
  const items = filteredItems.slice(paginationStartsAt, paginationEndsAt);

  return (
    <React.Fragment>
      {hasSearch && (
        <Input
          fluid
          name="search"
          value={search}
          placeholder="Buscar"
          onChange={({ target }) => setSearch(target.value)}
        />
      )}
      <Card.Group itemsPerRow={cardsQuantity} style={{ marginTop: "1rem" }}>
        {items.map((item, i) => (
          <ItemComponent key={i} {...item} />
        ))}
      </Card.Group>
      {hasPagination && totalPages > 1 && (
        <Container textAlign="center" fluid style={{ marginTop: "1rem" }}>
          <Pagination
            boundaryRange={0}
            defaultActivePage={1}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            siblingRange={1}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </Container>
      )}
    </React.Fragment>
  );
};

CardResultsListFrontend.propTypes = {
  data: PropTypes.array.isRequired,
  hasSearch: PropTypes.bool,
  hasPagination: PropTypes.bool,
  pageSize: PropTypes.number,
  defaultPage: PropTypes.number,
  hasSort: PropTypes.bool,
  searchPredicate: PropTypes.func,
  ItemComponent: PropTypes.func.isRequired,
};

CardResultsListFrontend.defaultProps = {
  hasSearch: false,
  hasPagination: false,
  pageSize: 10,
  defaultPage: 1,
  hasSort: false,
  cardsBySize: defaultCardsBySize,
  searchPredicate: () => true,
};
