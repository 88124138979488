import React from "react";
import { Location, navigate } from "@reach/router";
import { Menu, Dropdown } from "semantic-ui-react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { withUserContext } from "./Context";

import { MENU } from "../globals";

const MenuStyled = styled(Menu)`
  margin-top: 0;
  & a {
    color: #000 !important;
  }
`;

const MainMenu = ({ vertical = false, afterMenuItemSelection, ...props }) => {
  const handleItemSelection = (route) => {
    afterMenuItemSelection();
    navigate(`/${route}`);
  };

  const { userPrivileges } = props.user;
  return (
    <Location>
      {({ location }) => {
        const currentRouteBase = location.pathname.split("/")[1];

        return (
          <MenuStyled secondary vertical={vertical}>
            <Menu.Item
              active={currentRouteBase === ""}
              onClick={() => handleItemSelection("")}
            >
              DASHBOARD
            </Menu.Item>
            {userPrivileges &&
              MENU.map((menuGroupInfo) => {
                // Filter menu sections to the ones allowed for this user.
                const allowedSections = Object.keys(menuGroupInfo.sections)
                  .filter(
                    (v) =>
                      userPrivileges[v] &&
                      Object.values(userPrivileges[v]).filter((v) => v === true)
                        .length > 0
                  )
                  .map((k) => ({
                    title: menuGroupInfo.sections[k].title,
                    route: menuGroupInfo.sections[k].route || k,
                  }));

                // If a group has only one section, let's show the section
                // link directly (no submenu).
                return allowedSections.length > 1 ? (
                  <Dropdown
                    item
                    text={menuGroupInfo.groupTitle.toUpperCase()}
                    key={menuGroupInfo.groupTitle}
                  >
                    <Dropdown.Menu direction="left">
                      {allowedSections.map((v) => (
                        <Dropdown.Item
                          key={v.route}
                          active={currentRouteBase === v.route}
                          content={v.title}
                          onClick={() => handleItemSelection(v.route)}
                        />
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : allowedSections.length === 1 ? (
                  <Menu.Item
                    key={allowedSections[0].route}
                    active={currentRouteBase === allowedSections[0].route}
                    onClick={() =>
                      handleItemSelection(allowedSections[0].route)
                    }
                  >
                    {allowedSections[0].title.toUpperCase()}
                  </Menu.Item>
                ) : null;
              })}
          </MenuStyled>
        );
      }}
    </Location>
  );
};

MainMenu.propTypes = {
  vertical: PropTypes.bool,
  afterMenuItemSelection: PropTypes.func,
};

MainMenu.defaultProps = {
  vertical: false,
  afterMenuItemSelection: () => true,
};

export default withUserContext(MainMenu);
