import { createGlobalStyle } from "styled-components";

const COLORS = {
  // Brand colors
  main: "#af1685",
  dark: "#1e2839",
  gray: "#a2a0b1",
  "soft-gray": "#f0f3f8",
};

export default createGlobalStyle`
  * {
  box-sizing: border-box;
  }
  html,
  body {
    margin: 0;
    padding: 0;
    // padding: 0.6rem 0.8rem 0 0.8rem;
    color: ${COLORS["dark"]};  

    /* ****** */
    /* TITLES */
    /* ****** */
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
      font-weight: bold;
      margin-bottom: 1.5rem;

      &.font-light {
        font-weight: 300;
      }
    }

    h1,
    .h1 {
      font-size: 3.125rem;
      line-height: 3.1875rem;
    }

    h2,
    .h2 {
      font-size: 2.5rem;
      line-height: 2.8125rem;
    }

    h3,
    .h3 {
      font-size: 2.1875rem;
      line-height: 2.8125rem;
    }

    h4,
    .h4 {
      font-size: 1.8125rem;
      line-height: 2.1875rem;
    }

    h5,
    .h5 {
      font-size: 1.5625rem;
      line-height: 1.75rem;
    }

    h6,
    .h6 {
      font-size: 1.1875rem;
      line-height: 1.4375rem;
    }

    /* ********** */
    /* PARAGRAPHS */
    /* ********** */

    p,
    .p {
      display: block;
      font-size: 1.375rem;
      line-height: 2.1875rem;
      margin-bottom: 1rem;
      color: ${COLORS["dark"]};
    }

    /* ****** */
    /* INPUTS */
    /* ****** */

    label ~ input[type="search"],
    label ~ input[type="text"],
    label ~ input[type="password"],
    label ~ input[type="email"],
    label ~ input[type="tel"],
    label ~ input[type="date"],
    label ~ input[type="datetime-local"],
    label ~ textarea {
      margin-top: 0.75rem;
    }

    input[type="search"],
    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="tel"],
    input[type="date"],
    input[type="datetime-local"],
    textarea {
      padding: 0.5rem 0.75rem;
      background-color: ${COLORS["soft-gray"]};
      width: 100%;
      color: ${COLORS.main};
      border: solid 1px ${COLORS.dark};
      border-radius: 0.25rem;

      &:hover {
        border-color: ${COLORS.main};
      }

      &:focus {
        border-color: ${COLORS.main};
        outline: 2px solid transparent;
        outline-offset: 2px;
      }
    }

  }
  a {
    color: ${(props) =>
      props.aHoverColor ? props.aHoverColor : "#000"} !important;
  }
  button,
  a:hover {
    cursor: pointer;
  }
  a:hover button {
    opacity: 0.85;
  }
  #root {
    height: 100%;
  }

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    background-color: ${COLORS["soft-gray"]};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${COLORS["gray"]};

    &:hover {
      background-color: ${COLORS["main-alternative"]};
    }
  }

  ::-webkit-scrollbar-track {
    background-color: ${COLORS["soft-gray"]};
  }

  table thead > tr {
    > th {
      background-color: ${(props) =>
        props.aHoverColor ? props.aHoverColor : "#000"} !important;
      color: white !important;
    }
  }

  //Icons inside cards
  .ui.card .meta :last-child, .ui.cards>.card .meta :last-child {
    margin-right:0.2rem;
  }

  .ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before {
    // background-color: #2185d0!important;
    background-color: ${(props) => props.mainColor} !important;
  }
`;
