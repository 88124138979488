import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { Form, Segment, Button, Modal, Confirm } from "semantic-ui-react";
import { withUserContext } from "../Context";

class FilesUploaderComponent extends PureComponent {
  static propTypes = {
    defaultFiles: PropTypes.array,
    errors: PropTypes.object,
    handleFileChange: PropTypes.func,
    user: PropTypes.object,
  };

  static defaultProps = {
    defaultFiles: [],
    errors: {},
    handleFileChange: () => null,
    user: {},
  };

  state = {
    status: "waiting",
    files: this.props.defaultFiles,
    filesErrors: {},
    deleteFileConfirmIndex: -1,
  };

  /* LIFECYCLE HOOKS */

  componentDidUpdate(prevProps, prevState) {
    if (prevState.files !== this.state.files)
      this.props.handleFileChange(this.state.files);
  }

  /* HANDLERS */

  // When select a file to upload.
  handleFileSelection = (e, values) => {
    const filesData = e.target.files;
    this.setState({ status: "loading" });

    for (let i = 0, l = filesData.length; i < l; i++) {
      const fileData = filesData[i];

      if (fileData.size > 26214400) {
        this.setState((prevState) => ({
          ...prevState,
          filesErrors: {
            ...prevState.filesErrors,
            [fileData.name]: "El tamaño máximo de archivo es de 25MB",
          },
        }));
        continue;
      }

      this.readFile(fileData, "urldata")
        .then((fileContent) => {
          this.setState((prevState) => ({
            status: "waiting",
            files: [
              ...prevState.files,
              {
                name: "",
                fileName: fileData.name,
                data: fileContent,
                isNew: true,
              },
            ],
          }));
        })
        .catch((error) => {
          console.error(error);
          this.setState({ status: "error" });
        });
    }
  };

  handleFileNameChange = (e, { value, index }) => {
    this.setState((prevState) => ({
      files: [
        ...prevState.files.slice(0, index),
        { ...prevState.files[index], name: value },
        ...prevState.files.slice(index + 1),
      ],
    }));
  };

  handleRemoveFile = (index) => {
    this.setState((prevState) => ({
      files: prevState.files.filter((v, i) => i !== index),
      deleteFileConfirmIndex: -1,
    }));
  };

  handleCloseFilesErrorsModal = () => {
    this.setState({ filesErrors: {} });
  };

  /* FUNCTIONS */

  // Reads the data in a file and return it using promises.
  readFile = (file, output = "text", encoding = "UTF-8") => {
    return new Promise((resolve, reject) => {
      if (!file) reject(new Error("No se encuentra el fichero"));

      var reader = new FileReader();
      reader.onload = (fileContent) => {
        resolve(fileContent.target.result);
      };
      reader.onerror = (e) => {
        reject(e.target.error);
      };
      if (output === "text") reader.readAsText(file, encoding);
      else if (output === "urldata") reader.readAsDataURL(file);
      else if (output === "binary") reader.readAsBinaryString(file);
      else if (output === "arraybuffer") reader.readAsArrayBuffer(file);
      else reject(new Error("Formato de salida de fichero desconocido"));
    });
  };

  render() {
    const { user, errors } = this.props;
    const { files, filesErrors } = this.state;

    return (
      <Fragment>
        <Modal
          open={Object.keys(filesErrors).length > 0}
          onClose={this.handleCloseFilesErrorsModal}
          size="small"
          header="Error en archivo(s) seleccionado(s)"
          content={
            <ul style={{ margin: "2rem 0" }}>
              {Object.keys(filesErrors).map((fileName) => (
                <li key={fileName} style={{ margin: "0.4rem 0" }}>
                  <strong>
                    [<i>{fileName}</i>]
                  </strong>
                  : {filesErrors[fileName]}.
                </li>
              ))}
            </ul>
          }
        />
        <Form.Input
          type="file"
          multiple
          label="Documentos"
          name="file"
          onChange={this.handleFileSelection}
        />
        {files.length > 0 &&
          files.map((v, i) => (
            <Segment
              key={v.id || v.name || v.fileName}
              style={{
                padding: "0.6rem 0.6rem 0.6rem 1rem",
                margin: "0 0 0.8rem 2rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "flex-end",
                }}
              >
                <label style={{ width: "100%" }}>
                  <b>Nombre del documento</b>
                </label>
                <div style={{ flex: "1 1 auto" }}>
                  <Form.Input
                    index={i}
                    fluid
                    name="name"
                    value={v.name}
                    onChange={this.handleFileNameChange}
                    error={errors[`files[${i}].name`] !== undefined}
                    autoFocus
                  />
                </div>
                <div
                  style={{
                    width: "40%",
                    fontStyle: "italic",
                    margin: "0.6rem 1.6rem",
                  }}
                >
                  {v.fileName}
                </div>
                {(user.userRole.name !== "operator" || v.isNew) && (
                  <Fragment>
                    <Button
                      icon="trash"
                      onClick={() =>
                        this.setState({ deleteFileConfirmIndex: i })
                      }
                    />
                    <Confirm
                      open={this.state.deleteFileConfirmIndex === i}
                      content="¿Esta seguro que desea eliminar el archivo?"
                      onCancel={() =>
                        this.setState({ deleteFileConfirmIndex: -1 })
                      }
                      onConfirm={() => this.handleRemoveFile(i)}
                    />
                  </Fragment>
                )}
              </div>
              {errors[`files[${i}].name`] !== undefined && (
                <div style={{ color: "red", margin: "0.6rem 0 00 0" }}>
                  {errors[`files[${i}].name`].map((v, i) => (
                    <div key={`${i}-${v}`}>- {v}</div>
                  ))}
                </div>
              )}
            </Segment>
          ))}
      </Fragment>
    );
  }
}

export const FilesUploader = withUserContext(FilesUploaderComponent);
