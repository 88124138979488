const siteInfo = {
  "mantenimiento.iteisa.com": {
    id: "iteisa",
    api: "https://api.mantenimiento.zitelia.com/v1",
    // api: 'http://localhost:8765/v1',
    name: "ITEISA",
    logo: { file: "logo-iteisa.svg", headerHeight: "40px" },
    suiThemeFolder: "semantic/dist",
    color1: "rgba(175, 22, 133)",
    // color1:"gray",
    suiColor1: "purple",
  },
  "mantenimiento.hospitalsantaclotilde.es": {
    id: "hsc",
    api: "https://api.mantenimiento.zitelia.com/v1",
    name: "Hospital Santa Clotilde",
    logo: { file: "logo-hsc.png", headerHeight: "60px" },
    suiThemeFolder: "semantic/dist",
    color1: "#924D8B",
    suiColor1: "purple",
  },
  "puertosantander.mantenimiento.iteisa.biz": {
    id: "hsc",
    api: "https://api.mantenimiento.zitelia.com/v1",
    name: "Puerto de santander",
    logo: { file: "logo-ps.png", headerHeight: "60px" },
    suiThemeFolder: "semantic/dist",
    color1: "#0073c0",
    suiColor1: "#626d6b",
  },
};
export const SITE =
  siteInfo[window.location.host] ||
  // siteInfo['mantenimiento.hospitalsantaclotilde.es']
  siteInfo["mantenimiento.iteisa.com"];

export const APIBASEURL = SITE.api;
export const APIREQUESTOPTIONS = {
  responseType: "json",
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

export const MENU = [
  {
    groupTitle: "Áreas",
    sections: {
      areas: { title: "Áreas" },
    },
  },
  {
    groupTitle: "Empresas",
    sections: {
      companies: { title: "Empresas" },
    },
  },
  {
    groupTitle: "Recursos",
    sections: {
      items: { title: "Equipos" },
    },
  },
  {
    groupTitle: "Incidencias",
    sections: {
      incidences: { title: "Incidencias" },
    },
  },
  {
    groupTitle: "Acciones",
    sections: {
      actions: { title: "Acciones individuales" },
      series: { title: "Eventos programados" },
    },
  },
  {
    groupTitle: "Recursos",
    sections: {
      homologationstates: { title: "Estados de homologación" },
      itemtypes: { title: "Tipos de equipo" },
      actiontypes: { title: "Tipos de acción" },
      actionpriorities: { title: "Prioridades de acción" },
      actionstates: { title: "Estados de acción" },
    },
  },
  {
    groupTitle: "Configuración",
    sections: {
      //owners: { title: 'Propietarios' },
      //roles: { title: 'Roles' },
      users: { title: "Usuarios" },
    },
  },
];

export const COLORS = [
  { key: "0", text: "", value: "" },
  {
    key: "green",
    text: "Verde",
    value: "green",
    label: { color: "green", empty: true, circular: true },
  },
  {
    key: "orange",
    text: "Naranja",
    value: "orange",
    label: { color: "orange", empty: true, circular: true },
  },
  {
    key: "yellow",
    text: "Amarillo",
    value: "yellow",
    label: { color: "yellow", empty: true, circular: true },
  },
  {
    key: "red",
    text: "Rojo",
    value: "red",
    label: { color: "red", empty: true, circular: true },
  },
  {
    key: "blue",
    text: "Azul",
    value: "blue",
    label: { color: "blue", empty: true, circular: true },
  },
  {
    key: "brown",
    text: "Marrón",
    value: "brown",
    label: { color: "brown", empty: true, circular: true },
  },
  {
    key: "grey",
    text: "Gris",
    value: "grey",
    label: { color: "grey", empty: true, circular: true },
  },
  {
    key: "black",
    text: "Negro",
    value: "black",
    label: { color: "black", empty: true, circular: true },
  },
];

export const MONTHS = {
  1: { sort: "Ene", long: "Enero" },
  2: { sort: "Feb", long: "Febrero" },
  3: { sort: "Mar", long: "Marzo" },
  4: { sort: "Abr", long: "Abril" },
  5: { sort: "May", long: "Mayo" },
  6: { sort: "Jun", long: "Junio" },
  7: { sort: "Jul", long: "Julio" },
  8: { sort: "Ago", long: "Agosto" },
  9: { sort: "Sep", long: "Septiembre" },
  10: { sort: "Oct", long: "Octubre" },
  11: { sort: "Nov", long: "Noviembre" },
  12: { sort: "Dic", long: "Diciembre" },
};

export const PAGESIZE = 100;

export const SERIEPERIODICITIES = {
  "twelve-year": "Duodecenal",
  "ten-year": "Decenal",
  "six-year": "Sexenal",
  "five-year": "Quinquenal",
  "three-year": "Trienal",
  "two-year": "Bienal",
  anual: "Anual",
  "six-month": "Semestral",
  "four-month": "Cuatrimestral",
  "three-month": "Trimestral",
  monthly: "Mensual",
  weekly: "Semanal",
  daily: "Diaria",
};

export const requestStates = {
  SENT: "SENT",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
};
