import React, { Component, Fragment } from "react";
import { Router, Location } from "@reach/router";

import { withUserContext } from "../components/Context";
import Loadable from "../components/Loadable";
import GlobalStyle from "../components/styled/GlobalStyle";
import ScreensLogin from "./Login";
import Messages from "../components/Messages";
import Header from "../components/Header";
import Dashboard from "./Dashboard";

import { SITE } from "../globals";

// Code Splitting: Prepare component to be loaded asynchronously when required.
const AsyncScreensProfile = Loadable({ loader: () => import("./Profile") });
const AsyncScreensUser = Loadable({ loader: () => import("./User") });
const AsyncScreensArea = Loadable({ loader: () => import("./Area") });
const AsyncScreensCompany = Loadable({ loader: () => import("./Company") });
const AsyncScreensItem = Loadable({ loader: () => import("./Item") });
const AsyncScreensIncidence = Loadable({ loader: () => import("./Incidence") });
const AsyncScreensAction = Loadable({ loader: () => import("./Action") });
const AsyncScreensSerie = Loadable({ loader: () => import("./Serie") });
const AsyncScreensItemType = Loadable({
  loader: () => import("./ItemType"),
});
const AsyncScreensActionType = Loadable({
  loader: () => import("./ActionType"),
});
const AsyncScreensActionPriority = Loadable({
  loader: () => import("./ActionPriority"),
});
const AsyncScreensActionState = Loadable({
  loader: () => import("./ActionState"),
});
const AsyncScreensHomologationState = Loadable({
  loader: () => import("./HomologationState"),
});

class Root extends Component {
  componentDidMount() {
    this.props.user.checkUserStatus();
  }

  render() {
    const { userStatus, userPrivileges, logoutUser } = this.props.user;

    if (userStatus === "checking") return null;
    if (userStatus !== "logged-in")
      return (
        <Fragment>
          <GlobalStyle />
          <ScreensLogin />
        </Fragment>
      );

    return (
      <Location>
        {({ location }) => {
          // If current route is not allowed for this user, logout him/her.
          const currentRouteBase = location.pathname.split("/")[1];
          if (
            currentRouteBase &&
            currentRouteBase !== "profile" &&
            !userPrivileges[currentRouteBase]
          ) {
            logoutUser();
            return null;
          }

          return (
            <Fragment>
              <GlobalStyle aHoverColor={SITE.color1} mainColor={SITE.color1} />
              <Header />
              <Router primary={false}>
                <Dashboard path="/" />
                <AsyncScreensProfile path="profile/*" />
                <AsyncScreensUser path="users/*" />
                <AsyncScreensArea path="areas/*" />
                <AsyncScreensCompany path="companies/*" />
                <AsyncScreensItem path="items/*" />
                <AsyncScreensIncidence path="incidences/*" />
                <AsyncScreensAction path="actions/*" />
                <AsyncScreensSerie path="series/*" />
                <AsyncScreensItemType path="itemtypes/*" />
                <AsyncScreensActionType path="actiontypes/*" />
                <AsyncScreensActionPriority path="actionpriorities/*" />
                <AsyncScreensActionState path="actionstates/*" />
                <AsyncScreensHomologationState path="homologationstates/*" />
              </Router>
              <Messages />
            </Fragment>
          );
        }}
      </Location>
    );
  }
}

export default withUserContext(Root);
