import React, { PureComponent } from 'react'
import { Image } from 'semantic-ui-react'

import { SITE } from '../globals'
// Dynamic import() doesn't allow me to access SITE directly (WTF)
const siteLogo = SITE.logo.file

class Logo extends PureComponent {
  state = {
    logo: null,
  }

  componentDidMount() {
    this.loadLogo()
  }

  loadLogo = () => {
    import(`../images/${siteLogo}`)
      .then(logo => {
        this.setState({ logo: logo.default })
      })
      .catch(e => {
        console.error(e)
      })
  }

  render() {
    const logo = this.state.logo
    return logo ? <Image src={logo} {...this.props} /> : null
  }
}

export default Logo
