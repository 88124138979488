import React from 'react'
import L from 'react-loadable'

// Component loader personalized to avoid repeating options.
const Loadable = opts =>
  L({
    loading: () => <div>Loading...</div>,
    delay: 500,
    ...opts,
  })

export default Loadable
