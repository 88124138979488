import React from "react";
import { Button, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { navigate } from "@reach/router";

import { withUserContext } from "./Context";
import { SITE } from "../globals";

const StyledUserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  color: ${SITE.color1};
  border: none;
  box-shadow: none !important;
`;

const UserData = ({ user, ...rest }) => {
  if (user.userStatus !== "logged-in") return null;

  return (
    <div
      style={{
        textAlign: "right",
        justifySelf: "stretch",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      {...rest}
    >
      <StyledUserInfoContainer>
        <Button
          compact
          circular
          basic
          onClick={() => navigate("/profile")}
          icon={() => <StyledIcon name="user" circular />}
        />
      </StyledUserInfoContainer>
      <StyledUserInfoContainer>
        <Button
          compact
          circular
          basic
          onClick={user.logoutUser}
          icon={() => <StyledIcon name="log out" circular />}
        />
      </StyledUserInfoContainer>
    </div>
  );
};

export default withUserContext(UserData);
