import styled from "styled-components";

import { TableBackEnd } from "./TableBackend";

//Component that aims to force responsive bahaviour in tables with too much
//info that ends overflowing it's div before responsive from semantic ui is triggered
export const StyledTable = styled(TableBackEnd)`
  @media (min-width: ${(props) => props.lowerbound}px) and (max-width: ${(
      props
    ) => props.upperbound}px) {
    width: 100% !important;
    thead > tr > th.collapsing {
      border-radius: 0px !important;
    }
    thead > tr > th:first-child {
      border-radius: 0.28571429rem 0.28571429rem 0 0 !important;
    }
    tbody,
    thead {
      tr {
        display: block !important;
        border: none;
      }
      td,
      th {
        display: block !important;
        border: none;
        height: min-content;
        width: 100% !important;
        padding: 0px !important;
        padding: 0.25em 0.75em !important;
      }
      tr:nth-child(even) td,
      th {
        border: none;
      }
      tr:nth-child(odd) td,
      th {
        border: none;
      }
    }
  }
  tbody tr {
    padding-top: 1em;
    padding-bottom: 1em;
    border-bottom-color: rgba(34, 36, 38, 0.15);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-top-color: rgba(34, 36, 38, 0.15);
    border-top-style: solid;
    border-top-width: 1px;
  }
  thead th {
    border-bottom: 0px !important;
  }
`;
