import React, { PureComponent, createContext } from 'react'
import axios from 'axios'
import * as Sentry from '@sentry/browser'

import { APIBASEURL } from '../../globals'

const UserContext = createContext()

export class UserProvider extends PureComponent {
  state = {
    userStatus: 'checking',
    userRole: {},
    userPrivileges: {},
    userId: null,
    userName: null,
    userEmail: null,
  }

  setUserLoggedOut = () => {
    this.setState({
      userStatus: 'logged-out',
      userRole: {},
      userPrivileges: {},
      userId: null,
      userLogin: null,
      userName: null,
      userEmail: null,
    })
  }

  setUserInfo = ({ data }) => {
    const { user, privileges } = data;
    const {
      role: userRole,
      id: userId,
      username: userLogin,
      name: userName,
      email: userEmail
    } = user;

    this.setState({
      userStatus: 'logged-in',
      userRole,
      userId,
      userLogin,
      userName,
      userEmail,
      userPrivileges: Object.keys(privileges).reduce(
        (t, k) =>
          Object.values(privileges[k]).filter(v => v === true).length > 0
            ? { ...t, [k.toLowerCase()]: privileges[k] }
            : t,
        {}
      ),
    })
  }

  checkUserStatus = () => {
    axios({
      method: 'GET',
      url: `${APIBASEURL}/logged`,
      responseType: 'json',
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.data.data.logged) {
          this.setUserInfo(response.data)
        } else {
          this.setUserLoggedOut()
        }
      })
      .catch(error => {
        Sentry.captureException(error);
        console.error(error)
        this.setUserLoggedOut()
      })
  }

  userIsAdmin = () => {
    const {
      userRole: { name },
    } = this.state
    return name === 'admin'
  }

  userIsDeity = () => {
    const {
      userRole: { name },
    } = this.state
    return name === 'deity'
  }

  logoutUser = () => {
    axios({
      method: 'GET',
      url: `${APIBASEURL}/logout`,
      responseType: 'json',
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        this.setUserLoggedOut()
      })
      .catch(error => {
        Sentry.captureException(error);
        console.error(error)
        this.setUserLoggedOut()
      })
  }

  render() {
    const user = {
      ...this.state,
      setUserInfo: this.setUserInfo,
      checkUserStatus: this.checkUserStatus,
      userIsAdmin: this.userIsAdmin,
      userIsDeity: this.userIsDeity,
      logoutUser: this.logoutUser,
    }
    return <UserContext.Provider value={user}>{this.props.children}</UserContext.Provider>
  }
}

export function withUserContext(Component) {
  return function WithUserContextComponent(props) {
    return (
      <UserContext.Consumer>{user => <Component {...props} user={user} />}</UserContext.Consumer>
    )
  }
}
