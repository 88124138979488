import React, { useEffect } from "react";
import { Segment } from "semantic-ui-react";
import { css } from "emotion";

import LoginForm from "@bit/rigobauer.iteisa-components.semantic-ui.login-form";
import { withUserContext } from "../components/Context";
import Logo from "../components/Logo";

import { APIBASEURL, APIREQUESTOPTIONS, SITE } from "../globals";

const ScreensLogin = ({ user }) => {
  useEffect(() => {
    document.querySelector('input[name="login"]').focus();
  }, []);

  const mainStyles = css({
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  });

  const loginStyles = css({
    width: "90%",
    padding: "3rem !important",
    animation: "fadeInOpacity 1s ease 0s 1",
    "@media (min-width: 576px)": {
      width: "450px",
      padding: "4rem !important",
    },
  });

  const logoStyles = css({
    width: "60%",
    marginBottom: "2rem",
    "@media (min-width: 576px)": {
      width: "180px",
    },
  });

  const loginResponseValidation = ({ data }) => data.data.user && data.success;

  const handleLoginSuccess = (response) => {
    user.setUserInfo(response.data);
  };

  return (
    <main className={mainStyles}>
      <Segment className={loginStyles}>
        <Logo centered className={logoStyles} />
        <LoginForm
          urlLogin={`${APIBASEURL}/login`}
          apiRequestOptions={APIREQUESTOPTIONS}
          loginUrlVar="username"
          pwdUrlVar="password"
          buttonProps={{ primary: true, style: { background: SITE.color1 } }}
          errorMessageLogin={
            "Mmmm... Algo raro ha sucedido. Será mejor que lo vuelvas a intentar."
          }
          loginResponseValidation={loginResponseValidation}
          handleLoginSuccess={handleLoginSuccess}
        />
      </Segment>
    </main>
  );
};

export default withUserContext(ScreensLogin);
