import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Message,
  Segment,
  Pagination,
  Container,
} from "semantic-ui-react";

import { useFetch, useScreenSize } from "../../hooks";
import { requestStates } from "../../globals";

const cardsBySize = {
  "2xl": 7,
  xl: 5,
  lg: 5,
  md: 3,
  sm: 1,
};

export const CardResultsListBackend = ({
  url,
  hasPagination,
  pageSize,
  pageUrlVar,
  pageSizeUrlVar,
  defaultPage,
  totalPagesExtraction,
  dataListHasChanged,
  ItemComponent,
}) => {
  const getApiEndpoint = (url) =>
    hasPagination
      ? `${url}${pageUrlVar}=${currentPage}&${pageSizeUrlVar}=${pageSize}&`
      : url;

  //HOOKS
  const [currentPage, setCurrentPage] = useState(defaultPage);
  const { status, data } = useFetch(getApiEndpoint(url), dataListHasChanged);
  const [cardsQuantity, setCardsQuantity] = useState(7);
  const [screenWidth] = useScreenSize();

  useEffect(() => {
    setCardsQuantity(cardsBySize[screenWidth]);
  }, [screenWidth]);

  //CHANGE HANDLER
  const handlePageChange = (_, { activePage }) => setCurrentPage(activePage);

  //CONDITIONAL RENDERS
  if (status === requestStates.SENT)
    return <Segment style={{ minHeight: "10rem" }} loading />;

  if (status === requestStates.FAILED)
    return (
      <Message
        error
        content="Ha habido un error crítico, contacta con el administrador del sistema"
      />
    );

  const totalPages = totalPagesExtraction(data);
  const items = data.data;

  return (
    <React.Fragment>
      <Card.Group itemsPerRow={cardsQuantity} style={{ marginTop: "1rem" }}>
        {items.map((item, i) => (
          <ItemComponent key={i} {...item} />
        ))}
      </Card.Group>
      {hasPagination && (
        <Container textAlign="center" fluid style={{ marginTop: "1rem" }}>
          <Pagination
            boundaryRange={0}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            siblingRange={1}
            totalPages={totalPages}
            activePage={currentPage}
            onPageChange={handlePageChange}
          />
        </Container>
      )}
    </React.Fragment>
  );
};

CardResultsListBackend.propTypes = {
  url: PropTypes.string.isRequired,
  handleApiRequestError: PropTypes.func,
  hasPagination: PropTypes.bool,
  pageUrlVar: PropTypes.string,
  pageSizeUrlVar: PropTypes.string,
  pageSize: PropTypes.number,
  defaultPage: PropTypes.number,
  totalPagesExtraction: PropTypes.func,
  dataListHasChanged: PropTypes.bool,
  ItemComponent: PropTypes.func.isRequired,
};

CardResultsListBackend.defaultProps = {
  url: "",
  handleApiRequestError: () => null,
  hasPagination: true,
  pageUrlVar: "page",
  pageSizeUrlVar: "limit",
  pageSize: 25,
  defaultPage: 1,
  totalPagesExtraction: () => 1,
  dataListHasChanged: false,
};
