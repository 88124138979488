import React, { Component } from "react";
import { Divider } from "semantic-ui-react";

import NextEventsWidget from "../components/Widgets/NextEvents";
import OpenIncidencesWidget from "../components/Widgets/OpenIncidences";

class Dashboard extends Component {
  render() {
    return (
      <div style={{ marginTop: "2rem" }}>
        <NextEventsWidget />
        <Divider />
        <OpenIncidencesWidget />
      </div>
    );
  }
}

export default Dashboard;
