import React, { Fragment, useState } from "react";
import { Button, Confirm, Modal } from "semantic-ui-react";

import { withApiContext } from "../Context";

export const DeleteButton = withApiContext(
  ({ section, itemId, api, handleAfterDelete, endpoint }) => {
    const logoutWhenError = false;
    const [showConfirm, setShowConfirm] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const deleteItem = () => {
      setDeleting(true);
      setShowConfirm(false);
      const { deleteRequest } = api;
      const apiEndpoint = endpoint || section;
      deleteRequest(`/${apiEndpoint}/${itemId}`, logoutWhenError)
        .then(() => setDeleting(false))
        .catch((err) => {
          setShowErrorModal(true);
          setDeleting(false);
        })
        .finally(handleAfterDelete);
    };

    return (
      <Fragment>
        <Button
          title="Eliminar"
          basic
          loading={deleting}
          icon={deleting ? "" : "trash"}
          compact
          onClick={deleting ? undefined : () => setShowConfirm(true)}
        />
        <Confirm
          open={showConfirm}
          onCancel={() => setShowConfirm(false)}
          onConfirm={deleteItem}
          content="¿Está seguro?"
        />
        <Modal
          onClose={() => setShowErrorModal(false)}
          open={showErrorModal}
          content={
            <Fragment>
              <Modal.Header>Error eliminando el recurso</Modal.Header>
              <Modal.Content>
                Esto suele suceder cuando hay otros recursos relacionados entre
                sí. Comprueba que no has intentado eliminar un equipo que tiene
                una acción (individual o programada) asociada
              </Modal.Content>
            </Fragment>
          }
        />
      </Fragment>
    );
  }
);
