import React, { Fragment } from "react";
import { Link } from "@reach/router";
import { Button } from "semantic-ui-react";

import { withUserContext, withApiContext } from "../components/Context";
import { DeleteButton } from "./UI";

const CrudRowButtons = ({
  section,
  endpoint,
  itemId,
  skippedActions = [],
  handleAfterDelete,
  user: { userPrivileges },
}) => {
  return (
    <Fragment>
      {!skippedActions.includes("view") && (
        <Link to={`/${section}/${itemId}`}>
          <Button title="Ver" compact basic icon="eye" />
        </Link>
      )}
      {!skippedActions.includes("update") && userPrivileges[section].update && (
        <Link to={`/${section}/form/${itemId}`}>
          <Button title="Editar" basic compact icon="edit" />
        </Link>
      )}
      {!skippedActions.includes("delete") && userPrivileges[section].delete && (
        <DeleteButton
          section={section}
          itemId={itemId}
          handleAfterDelete={handleAfterDelete}
          endpoint={endpoint}
        />
      )}
    </Fragment>
  );
};

export default withUserContext(withApiContext(CrudRowButtons));
