import React, { PureComponent, createContext } from 'react'

const MessagesContext = createContext()

export class MessagesProvider extends PureComponent {
  state = {
    info: [],
    success: [],
    warning: [],
    error: [],
  }

  setMessages = (messagesArr, type = 'info') => {
    if (!['info', 'success', 'warning', 'error'].includes(type)) {
      console.error(`Tipo de mensaje (${type}) desconocido`)
      return
    }
    this.setState({ [type]: messagesArr })
  }

  addMessages = (messagesArr, type = 'info') => {
    if (!['info', 'success', 'warning', 'error'].includes(type)) {
      console.error(`Tipo de mensaje (${type}) desconocido`)
      return
    }
    this.setState(prevState => ({
      [type]: [...prevState[type], ...messagesArr],
    }))
  }

  removeMessage = (index, type = 'info') => {
    if (!['info', 'success', 'warning', 'error'].includes(type)) {
      console.error(`Tipo de mensaje (${type}) desconocido`)
      return
    }
    this.setState(prevState => ({
      [type]: prevState[type].filter((v, i) => i !== index),
    }))
  }

  resetMessages = (type = 'info') => {
    if (!['info', 'success', 'warning', 'error'].includes(type)) {
      console.error(`Tipo de mensaje (${type}) desconocido`)
      return
    }
    this.setState({ [type]: [] })
  }

  render() {
    const globalMessages = {
      ...this.state,
      setMessages: this.setMessages,
      addMessages: this.addMessages,
      removeMessage: this.removeMessage,
      resetMessages: this.resetMessages,
    }
    return (
      <MessagesContext.Provider value={globalMessages}>
        {this.props.children}
      </MessagesContext.Provider>
    )
  }
}

export function withMessagesContext(Component) {
  return function WithMessagesContextComponent(props) {
    return (
      <MessagesContext.Consumer>
        {globalMessages => (
          <Component {...props} globalMessages={globalMessages} />
        )}
      </MessagesContext.Consumer>
    )
  }
}
