import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Form } from "semantic-ui-react";
import * as Sentry from "@sentry/browser";

export class DropdownDbList extends PureComponent {
  CancelToken = axios.CancelToken;
  source = this.CancelToken.source();

  static propTypes = {
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    url: PropTypes.string.isRequired,
    filteredIds: PropTypes.array,
    apiRequestOptions: PropTypes.object,
  };
  static defaultProps = {
    component: Form.Dropdown,
    apiRequestOptions: {
      responseType: "json",
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    },
  };

  state = {
    status: "loading",
    data: [],
    cancelTokenSource: axios.CancelToken.source(),
  };

  componentDidMount() {
    this.getData(this.props.url, this.props.apiRequestOptions);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.url !== this.props.url)
      this.getData(this.props.url, this.props.apiRequestOptions);
  }

  componentWillUnmount() {
    this.state.cancelTokenSource.cancel("Petición cancelada");
  }

  getData = (url, apiRequestOptions) => {
    const { filteredIds } = this.props;
    axios({
      method: "get",
      url,
      cancelToken: this.state.cancelTokenSource.token,
      ...apiRequestOptions,
    })
      .then(({ data }) => {
        const list = [
          { key: 0, text: "", value: "" },
          ...Object.entries(data.data).reduce(
            (acc, [currentKey, currentValue]) =>
              filteredIds && filteredIds.includes(currentKey)
                ? acc
                : [
                    ...acc,
                    { key: currentKey, text: currentValue, value: currentKey },
                  ],
            []
          ),
        ];

        this.setState({
          status: "ok",
          data: list,
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          this.setState({ status: "error" });
        }
        Sentry.captureException(error);
        console.error(error);
      });
  };

  render() {
    const {
      component: Component,
      url,
      filteredIds,
      apiRequestOptions,
      ...rest
    } = this.props;
    const { status, data } = this.state;

    return (
      <Component loading={status === "loading"} options={data} {...rest} />
    );
  }
}
